import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
import leadbg from '../../assets/images/lead-text.png';

const Process = () => {

  const [Process, setProcess] = useState([])

  const cleanProcess = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const processtitle = fields.processtitle
        const processNumber = fields.processNumber
        const processDescription = fields.processDescription
        const updatedProcess = { processtitle, processNumber, processDescription }
        return updatedProcess
    })
    setProcess(cleanBG)
}, [])

const getprocess = useCallback( async () => {
  try{
    const testiresponse = await client.getEntries({ content_type: 'process', order: 'sys.createdAt' })
    const responseData = testiresponse.items
    console.log(responseData)

    if(responseData){
      cleanProcess(responseData)
      }else{
        setProcess([])
      }

  }
  catch(error){
    //console.log(error)
    //setIsTestimonials(false)
  }

}, [cleanProcess])

useEffect (() => {
  getprocess()
}, [getprocess])

  return (
    <>
        <section className='h-[500px] bg-secondary' style={{ backgroundImage: `url(${leadbg})`, }}>
        <div className="container mx-auto h-full flex items-center">
            <h1 className='text-5xl uppercase text-white font-black pb-8 relative after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] after:opacity-10'>Process</h1>
        </div>
    </section>


    {/* <section className='py-10'>
  <div className="container mx-auto">
  

 
  </div>
</section> */}

<section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <h1 class="text-3xl text-secondary block pb-8 mb-12 lg:mb-24 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10">It starts with a conversation

    </h1>
    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">

    {Process.map((item) => {
                const { processtitle, processNumber, processDescription } = item
                return (
                  <>
                  <div class="p-4  flex">
                    <div class="w-12 h-12 inline-flex items-center justify-center rounded-full bg-primary text-white mb-4 flex-shrink-0">
                    {processNumber}
                    </div>
                  <div class="flex-grow pl-6">
                    <h2 class="text-gray-900 text-lg title-font font-medium mb-2">{processtitle}
                    </h2>
                    <p class="leading-relaxed text-base">{processDescription}</p>

                  </div>
                  </div>
                </> 
                )

                }
  
                )}
      
    </div>
  </div>
</section>

<section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="text-center mb-20">
      <h1 class="sm:text-3xl text-2xl  title-font text-secondary uppercase mb-4 font-black">Building Permits vs Development Permits
</h1>
      <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">Lorem ipsum dolor sit amet consectetur adipiscing elit Phasellus ut ipsum ut felis convallis venenatis a non odio Phasellus vitae lectus mattis.

</p>
      <div class="flex mt-6 justify-center">
        <div class="w-16 h-1 rounded-full bg-primary inline-flex"></div>
      </div>
    </div>
    <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      <div class="p-4 md:w-1/2 flex flex-col text-left items-center">
       
        <div class="flex-grow">
          <h2 class="text-secondary text-lg title-font   mb-3 uppercase font-black">Shooting Stars</h2>
          <p class="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard.</p>
           
        </div>
      </div>
      <div class="p-4 md:w-1/2 flex flex-col text-left items-center">
         
        <div class="flex-grow">
          <h2 class="text-secondary text-lg title-font   mb-3 uppercase font-black">The Catalyzer</h2>
          <p class="leading-relaxed text-base">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard.</p>
           
        </div>
      </div>
       
    </div>
    
  </div>
</section>


    </>
  )
}

export default Process