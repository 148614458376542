import logo from 'assets/images/logo.png';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";

// const Navbar = () => {
//   const [nav, setNav] = useState(false);

//   const handleNav = () => {
//     setNav(!nav)
//   }
// }

const Header = () => {
  const [sticky, setSticky] = useState("");

   // on render, set listener
   useEffect(() => {
    //console.log("hello");
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 250 ? "is-sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  const classes = `header ${sticky}`;

    const [nav, setNav] = useState(false);
    const handleNav = () => {
      setNav(!nav)
    }

  return (
    <>
     <header className={classes}>
        <div className="container max-w-full px-4 flex justify-between">
            <div className="header-right flex relative lg:items-center w-full pt-4 lg:pt-0">
                  <button onClick={handleNav} className="lg:hidden absolute top-1/2 transform -translate-y-1/2 right-5 outline-none mobile-menu-button">
                    {!nav ? <FiMenu className='text-primary text-3xl' /> : <AiOutlineClose className='text-primary text-3xl' /> }
                  </button>
                  <div className="header-left-wrapper relative mr-6 xl:mr-10">
                    <Link to="/" className='logo'><img src={logo} alt="" /></Link>
                  </div>
                  <div className={`header-right-wrapper w-full block bg-secondary lg:bg-transparent absolute top-14 lg:top-auto lg:relative px-6 py-4 lg:p-0 ${!nav ? "hidden lg:block" : "block"}`}>
                    <div className="header-right-area lg:flex lg:items-center lg:justify-between">
                      <div className="navigation-area-wrap">
                        <ul className='main-menu'>
                          <li><Link to="#">Buildings</Link>
                            <ul className='sub-menu'>
                              <li><Link to="/business/homes">Homes</Link></li>
                              <li><Link to="/business/buildings">Buildings</Link></li>
                              <li><Link to="/business/acreage">Acreage</Link></li>
                              <li><Link to="/business/equestrian">Equestrian</Link></li>
                              <li><Link to="/business/farm">Farm</Link></li>
                            </ul>
                          </li>
                          <li><Link to="/why">WHY POST FRAME</Link></li>
                          <li><Link to="/process">PROCESS</Link></li>
                          <li><Link to="/inspiration">INSPIRATION</Link></li>
                          <li><Link to="/contact">Contact</Link></li>
                        </ul>
                      </div>
                      <div className="navigation-area-wrap-right lg:ml-auto lg:flex">
                        <Link to="#" className='call'><span>Call:</span> 000 111 1224</Link>
                        <ul className='lg:flex'>
                         <li><Link to="/careers">CAREERS</Link></li>
                         <li><Link to="/about">ABOUT</Link></li>
                         <li><Link to="/blog">BLOG</Link></li>
                        </ul>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
     </header>
    </>
  )
}

export default Header