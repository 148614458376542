import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
import { Link } from 'react-router-dom'

import CareerList from 'components/CareerList'

const Careers = () => {
  const [blogPost, setBlogPosts] = useState([])

    const cleanBlogPost = useCallback((rawData) => {
        const cleanPosts = rawData.map((post) => {
            const {sys, fields} = post
            const {id} = sys
            const title = fields.title
            const slug = fields.slug
            const location = fields.location
            const updatedPost = { id, title, slug, location }
            return updatedPost
        })

        setBlogPosts(cleanPosts)
    }, [])
     
    const getCorouselSliders = useCallback(
        async () => {
            //setIsPostLoading(true)
            try {
                const response = await client.getEntries( {content_type: 'careers'} )
                const responseData = response.items
                console.log(responseData)
                if(responseData) {
                    cleanBlogPost(responseData)
                } else {
                    setBlogPosts([])
                }
                //setIsPostLoading(false)
            } catch (error) {
                console.log(error)
                //setIsPostLoading(false)
            }
        }, [cleanBlogPost])

    useEffect (() => {
        getCorouselSliders()
    }, [getCorouselSliders])

    console.log(blogPost)
  return (
    <>
        <section className='h-[550px] bg-secondary'>
        <div className="container mx-auto h-full flex justify-center items-center">
            <h1 className='text-5xl uppercase text-white font-black'>Careers</h1>
        </div>
    </section>

    <section className='py-36'>
    <div className="container mx-auto">
          <h3 className='text-3xl text-secondary block pb-8 mb-24 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10'>Careers</h3>


          {blogPost.map((item) => {
                return (
                  <CareerList title={item.title} location={item.location} id={item.id} />  
                )
            }
            )}

          
     
           
      

    </div>
    </section>
    </>
  )
}


export default Careers