import React, { useState, useEffect, useCallback } from 'react'
//import buildings01 from '../assets/images/buildings-01.jpg';
import { Link } from 'react-router-dom';
import { client } from "../client";

const FeaturedCasestudiesList = (props) => {

  const [Casestudies, setCaseStudies] = useState([])

  const cleanCaseStudies = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const {id} = sys
        const caseType = fields.caseType
        const caseName = fields.caseName
        const caseDescription = fields.caseDescription.content[0].content[0].value
        const caseImage = fields.caseImage.fields.file.url
        //const caseDetailUrl = fields.caseDetailUrl
        const updatedCase = { id, caseType, caseName, caseDescription, caseImage}
        return updatedCase
    })
    setCaseStudies(cleanBG)
}, [])

const getcasestudies = useCallback( async () => {
  //setIsCaseStudies(true)
  try{
    const caseresponse = await client.getEntries({ content_type: 'caseStudies', 'fields.relatedBuildings.sys.contentType.sys.id':'buildings', 'fields.relatedBuildings.fields.slug[match]': 'buildings' })
    const responseData = caseresponse.items
    console.log(responseData)

    if(responseData){
      cleanCaseStudies(responseData)
      }else{
        setCaseStudies([])
      }

      //setIsCaseStudies(false)
  }
  catch(error){
    //console.log(error)
    setCaseStudies(false)
  }

}, [cleanCaseStudies])

useEffect (() => {
  getcasestudies()
}, [getcasestudies])

  return (
    <>
  {Casestudies.map((item) => {
          const { id, caseType, caseName, caseDescription, caseImage} = item
          return (
            <div className='container mx-auto my-24'>
          <div className="relative">
          <div className='h-[870px] relative bg-cover bg-no-repeat bg-center' style={{ backgroundImage: `url(${caseImage})`, }}></div>
          <article className={`max-w-lg absolute top-20 left-20 bg-white z-10 px-16 py-28 left-28`}>
              <h2 className='text-5xl uppercase text-secondary font-bold'>{caseName}</h2>
              <ul className='mt-8 mb-9'>
                <li className='border-t flex text-lg py-6'>
                  <div>Size: <span className='block text-xl font-bold'>60 x 120 x 18</span></div>
                  <div className='ml-auto'>SQ FT: <span className='block text-2xl font-bold'>1,500</span></div>
                </li>
                <li className='border-t flex text-lg py-6'>
                <span className='mr-5 font-bold uppercase text-2xl'>Starting From</span>
                <span className='text-primary text-4xl font-bold'>$100,000</span>
                </li>
              </ul>
              <Link className="btn !block" to="#">See What's Possible</Link>
          </article>
          </div>
      </div>
          )

      }
      
      )}
    </>
  )
}

export default FeaturedCasestudiesList