import React from 'react'
import { Link } from 'react-router-dom'

const CareerList = (props) => {
  return (
    <>
      <div className="border flex items-center justify-between p-5">
        <div className="career__info">
            <h3 className='text-4xl	 font-bold text-secondary mb-5'>{props.title}</h3>
            <p className="text-[#6f6f6f]">{props.location}</p>
          </div>
          <div className="career__cta">
            <Link to={`/careers/${props.id}`} className="btn !bg-primary">View Listing</Link>
          </div>
        </div>
    </>
  )
}

export default CareerList