import React, { useState, useEffect, useCallback } from 'react'
import {  useParams } from 'react-router-dom'
import GetInTouch from 'components/GetInTouch'
import { client } from '../../client'




const BlogDetails = () => {
  const [singleBlogPost, setSingleBlogPost] = useState([])
  const { id } = useParams()

  const cleanBlogDetail = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {fields} = home
        const blogtitle = fields.title
        const blogdetail = fields.postContent
        const blogdate = fields.date
        const blogauthor = fields.author
        const blogimage = fields.featuredImage.fields.file.url
        
        const updatedBlogDetail = { blogtitle, blogdetail, blogimage, blogdate, blogauthor }
        return updatedBlogDetail
    })
    setSingleBlogPost(cleanBG)
}, [])

  const getsingleblog = useCallback( async () => {
    //setIsTestimonials(true)
    try{
      const blogresponse = await client.getEntries({ content_type: 'blog', 'fields.slug[match]': id })
      const responseData = blogresponse.items
      console.log(responseData)
      if(responseData){
        cleanBlogDetail(responseData)
        }else{
          setSingleBlogPost([])
        }
        //setIsTestimonials(false)
    }
    catch(error){
      //console.log(error)
      //setIsTestimonials(false)
    }
  
  }, [cleanBlogDetail])
  useEffect (() => {
    getsingleblog()
  }, [getsingleblog])
   return (
    <>
   <section className='h-[550px] bg-secondary'>
        <div className="container mx-auto h-full flex justify-center items-center">
            <h1 className='text-5xl uppercase text-white font-black'>blog</h1>
        </div>
    </section>
    {singleBlogPost.map((item) => {
        console.log(item)
          const { blogtitle, blogdetail, blogimage, blogdate, blogauthor } = item
          return (
            <section className='py-10'>
            <div className="container mx-auto">

            <img src={blogimage}  alt="" />
            <h2 className="post-title pt-3 text-4xl">{blogtitle}</h2>
            <div className="text-lg mb-3">{blogdate} - {blogauthor}</div>
            <div className="post-description">
            <div dangerouslySetInnerHTML={{ __html: blogdetail }} />
           
            </div>
            </div>
            </section>
          )

      }
      
      )}

       



<GetInTouch />

    </>
  )
}

export default BlogDetails