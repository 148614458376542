// Import Swiper React components
import React, { useState, useEffect, useCallback } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import { Link } from "react-router-dom";
import { client } from "../client";

const CaseStudiesSlider = () => {
    //const [iscasestudies, setIsCaseStudies] = useState(false)
    const [Casestudies, setCaseStudies] = useState([])

  const cleanCaseStudies = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const {id} = sys
        const caseType = fields.caseType
        const caseName = fields.caseName
        const caseDescription = fields.caseDescription.content[0].content[0].value
        const caseImage = fields.caseImage.fields.file.url
        //const caseDetailUrl = fields.caseDetailUrl
        const updatedCase = { id, caseType, caseName, caseDescription, caseImage}
        return updatedCase
    })
    setCaseStudies(cleanBG)
}, [])

const getcasestudies = useCallback( async () => {
  //setIsCaseStudies(true)
  try{
    const caseresponse = await client.getEntries({ content_type: 'caseStudies' })
    const responseData = caseresponse.items
    console.log(responseData)

    if(responseData){
      cleanCaseStudies(responseData)
      }else{
        setCaseStudies([])
      }

      //setIsCaseStudies(false)
  }
  catch(error){
    //console.log(error)
    setCaseStudies(false)
  }

}, [cleanCaseStudies])

useEffect (() => {
  getcasestudies()
}, [getcasestudies])



  return (
    <>
    <section className="py-14 lg:py-28 bg-tertiary overflow-hidden">
    <div class="container mx-auto">
      <h3 className="text-3xl text-secondary block pb-8 mb-12 lg:mb-24 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10">Case studies</h3>
    </div>
    <div className="casestudy-slider container mx-auto">
    <Swiper
        slidesPerView={1}
        centeredSlides={true}
        spaceBetween={30}
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {Casestudies.map((item) => {
          const { caseType, caseName, caseDescription, caseImage} = item
          return (
            <SwiperSlide>
            <div className="container mx-auto !px-0 md:px-5">
                <div className="h-[762px] bg-cover overflow-hidden relative bg-no-repeat bg-center" style={{ backgroundImage: `url(${caseImage})`, }}>
                        <div className="max-w-lg absolute right-0 bottom-0 bg-secondary z-10 px-5 lg:px-16 py-20 lg:py-28 text-white">
                        <h3 className="text-lg uppercase text-[#fd5d14] font-bold">{caseType}</h3>
                        <h2 className="text-3xl lg:text-5xl font-black uppercase mt-4 mb-4 lg:mb-12">{caseName}</h2>
                        <p className="text-lg leading-8 mb-5 lg:mb-12 text-white">{caseDescription}</p>
                        <Link className="btn" to="#">See what is possible</Link>
                        </div>
                </div>
            </div>
            </SwiperSlide>
          )

      }
      
      )}
         
      </Swiper>
      </div>
      </section>
    </>
  )
}

export default CaseStudiesSlider