import React, { useState, useEffect, useCallback, useRef } from 'react'
import { client } from "../../client";
import {  useParams } from 'react-router-dom'
import { BrowserRouter as Router, Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

import leadbg from '../../assets/images/lead-text.png';
import GetInTouch from 'components/GetInTouch'



const BlogCategory = () => {
    //const [isPostLoading, setIsPostLoading] = useState(false)
    const [carouselPost, setCarouselPosts] = useState({posts:[]});
    const [pageData, setPageData] = useState({'limit':3,'page':1,'showLoadMore':false,'skip':0});
    let pageDataRef = useRef({'limit':3,'page':1,'showLoadMore':false,'skip':0})
    const { catname } = useParams();
    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));
    const cleanBlogPost = useCallback((rawData) => {
        const cleanPosts = rawData.map((post) => {
            const {sys, fields} = post
            const {id} = sys
            const postTitle = fields.title
            const postSlug = fields.slug
            const postFeaturedImage = fields.featuredImage.fields.file.url
            const updatedPost = { id, postTitle, postSlug, postFeaturedImage }
            setCarouselPosts(prevCarouselPosts => ({
                posts: [...prevCarouselPosts.posts, updatedPost]            
            }));
            //return updatedPost
        })

        //setCarouselPosts(cleanPosts)
    }, [])
     
    const getBlogCat = useCallback(
        async (catname,pageDataRef) => {
            
            //setIsPostLoading(true)
            try {
                const response = await client.getEntries({ content_type: 'blog', 'fields.category[match]': catname,  limit:pageDataRef.limit, skip:pageDataRef.skip });
                
                
                const maxPage = (response.total)/pageDataRef.limit;  
                let nextPage=pageDataRef.page; 
                let skip=pageDataRef.skip;                             
                if(pageDataRef.page < maxPage)
                {
                    setPageData(prevPageData => ({
                        ...prevPageData,
                        showLoadMore:true,
                        page:(nextPage+1),
                        skip:(skip+pageData.limit),
                    }));   
                }
                else
                {
                    setPageData(prevPageData => ({
                        ...prevPageData,
                        showLoadMore:false,
                    }));                    
                    
                }
                const responseData = response.items
                if(response.total) {
                    cleanBlogPost(responseData);                    
                } else {
                    setCarouselPosts([])
                }
                //setIsPostLoading(false)
            } catch (error) {
                console.log(error)
                //setIsPostLoading(false)
            }
        }, [cleanBlogPost]);

        

    useEffect (() => {
        setPageData({'limit':3,'page':1,'showLoadMore':false,'skip':0});
        setCarouselPosts({posts:[]});
        pageDataRef = {'limit':3,'page':1,'showLoadMore':false,'skip':0};
        getBlogCat(catname,pageDataRef);
   
    }, [catname])

    const loadMorePosts = () => {
        pageDataRef = pageData;
        getBlogCat(catname,pageDataRef);
    }
    //console.log(carouselPost)

  return (
    <>
    <section className='h-[500px] bg-secondary' style={{ backgroundImage: `url(${leadbg})`, }}>
        <div className="container mx-auto h-full flex items-center">
            <h1 className='text-5xl uppercase text-white font-black pb-8 relative after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] after:opacity-10'>blog</h1>
        </div>
    </section>

    <section>
        <div className="container mx-auto -mt-16 ">
            <div className="bg-[#0d3470] z-10 p-10">

                <div className="filter-item grid grid-cols-8">
                        <div className="check-div">
                        <Link to={`/blog/category/horses`}>
                            <input className="chkblog" type="checkbox" id="horses" checked={ catname === 'horses' ? true : false } />
                            <label for="horses">Horses</label>
                        </Link>
                        </div>
                        <div className="check-div">
                        <Link to={`/blog/category/hobby`}>
                            <input className="chkblog" type="checkbox" id="hobby" checked={ catname === 'hobby' ? true : false } />
                            <label for="hobby">Hobby</label>
                        </Link>
                        </div>
                        <div className="check-div">
                        <Link to={`/blog/category/aircaft`}>
                            <input className="chkblog" type="checkbox" id="aircraft" checked={ catname === 'aircaft' ? true : false } />
                            <label for="aircraft">Aircaft</label>
                        </Link>
                        </div>
                        
                    </div>
            </div>
            <div className="grid grid-cols-3 gap-16 py-20">
            {carouselPost.posts.map((item) => {
                return (
                    <article className=""><figure><Link to={`/blog/${item.postSlug}`}  className='block relative'><span className='w-14 h-14 bg-primary  absolute right-0 bottom-0 flex items-center justify-center'><FaArrowRight className='text-white text-2xl' /></span><img src={item.postFeaturedImage} alt="" /></Link></figure>
                    <h2 className='py-4'><Link to={`/blog/${item.postSlug}`} className='text-3xl uppercase font-black text-secondary'>
                    {item.postTitle}</Link></h2>
                    </article>
                )
            }
            )}
            </div>
           
            {pageData.showLoadMore && (
                <div className='text-center mb-32'><Link to='#' className='inline-block text-white uppercase font-bold py-5 px-5 min-w-[272px] bg-primary' onClick={loadMorePosts}>Load more</Link></div>
            )}
        </div>
    </section>

    <GetInTouch />
    </>
  )
}

export default BlogCategory