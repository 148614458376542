import GetInTouch from 'components/GetInTouch';
import React from 'react'
//import { Link } from "react-router-dom";
import heroimg from '../../assets/images/about-bg.jpg';
import leadbg from '../../assets/images/lead-text.png';

const About = () => {
  return (
    <>
    <section className="relative before:absolute before:bg-gradient-to-t before:from-transparent before:via-transparent before:to-black before:opacity-60 before:w-full before:h-full before:z-10">
      <div className="h-[841px] relative bg-cover bg-no-repeat bg-center flex items-center"
      style={{ backgroundImage: `url(${heroimg})`, }} >
            <div className="container mx-auto">
                <h2 className='text-5xl uppercase text-white font-bold relative block after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] pb-8 '>About Unique Builders</h2>
            </div>
      </div>
    </section>

    <section className='bg-[#0d3470] py-36' style={{ backgroundImage: `url(${leadbg})`, }}>
            <div className="container mx-auto">
                    <div className="grid lg:grid-cols-12">
                        <div className="lg:col-span-6">
                            <h2 className='text-5xl text-tertiary block pb-8 mb-24 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] after:opacity-10'>Our Story</h2>
                        </div>
                        <div className="lg:col-span-6 text-tertiary entry">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut ipsum ut felis convallis venenatis a non odio. Phasellus vitae lectus mattis, finibus massa nec, convallis nisi. Morbi non rhoncus turpis, at dignissim libero. Sed sollicitudin leo massa, a tempus est consequat nec.</p>
                            <p>Integer est libero, mattis et condimentum ut, gravida vitae tellus. Maecenas in nibh eu neque tempus tincidunt. Nunc dapibus mattis libero, sit amet pretium leo mollis eu. </p>
                            <p>Integer pulvinar mi leo, sed tempor ante tristique ut.</p>
                        </div>
                    </div>
            </div>
    </section>

    <section className='pt-40 pb-28'>
        <div className="container mx-auto">
            <div className="pb-20 mb-20 border-b border-[#d7d5d5]">
                <h3 className='text-5xl font-bold text-secondary uppercase mb-6'>OUR VALUES</h3>
                <p className='text-3xl uppercase text-secondary font-bold'>
                    We Believe That how you do <br />
                    anything is how you do<br />
                    Everything. This is how we do
                </p>
            </div>

            <div className="grid grid-cols-12 gap-20">
                <div className="lg:col-span-6">
                    <div className='md:max-w-lg'>
                        <h3 className='text-4xl uppercase font-bold mb-5'>We serve the customer, not the dollar.</h3>
                        <p className='text-[#6f6f6f] text-xl'>We believe that caring about the needs of our customers and employees first is necessary to build a lasting, respected business. We know that decisions based solely on profit are obviously selfish and a terrible foundation on which to build a company - or a building.</p>
                    </div>
                </div>
                
                <div className="lg:col-span-6">
                    <div className='md:max-w-lg md:ml-auto'>
                        <h3 className='text-4xl uppercase font-bold mb-5'>We keep our word and do what we promise.</h3>
                        <p className='text-[#6f6f6f] text-xl'>We believe that if our word can’t be relied on then neither can we. We know that we will earn the trust of our customers if we commit to one thing;  always tell the truth. Because you can’t fake the truth - for very long.</p>
                    </div>
                </div>

                <div className="lg:col-span-6">
                    <div className='md:max-w-lg'>
                        <h3 className='text-4xl uppercase font-bold mb-5'>We respect people's time, we don’t waste it.</h3>
                        <p className='text-[#6f6f6f] text-xl'>We believe that time has the highest value and that wasting it is disrespectful to our customers, our employees, and our business. We know that respecting people's time means that we must always be organized, always have a plan, and strive to start and finish projects on schedule.</p>
                    </div>
                </div>

                <div className="lg:col-span-6">
                    <div className='md:max-w-lg md:ml-auto'>
                        <h3 className='text-4xl uppercase font-bold mb-5'>We don’t whine, complain, or make excuses.</h3>
                        <p className='text-[#6f6f6f] text-xl'>We believe that no amount of whining, complaining or excuses will fix a problem. We know that challenges and problems are common in life and construction. When problems show up we focus our energy on the right next thing to do - finding a solution. Because there is always a solution.</p>
                    </div>
                </div>

                <div className="lg:col-span-6">
                    <div className='md:max-w-lg'>
                        <h3 className='text-4xl uppercase font-bold mb-5'>We take pride in perfectly square buildings.</h3>
                        <p className='text-[#6f6f6f] text-xl'>We believe that a commitment to perfectly square construction requires attention to detail, skill and experience. We know that these traits are required for a well-crafted building so we always train, support and place the highest value in our crew members as the backbone of our business.</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <GetInTouch />
    </>
  )
}

export default About