import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
import { Link } from 'react-router-dom';
import leadbg from '../../assets/images/lead-text.png';

const Team = () => {

  const [Team, setTeam] = useState([])

  const cleanTeam = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const memberName = fields.memberName
        const positionTitle = fields.positionTitle
        const teamMemberImage = fields.teamMemberImage.fields.file.url
        const updatedteam = { memberName, positionTitle, teamMemberImage }
        return updatedteam
    })
    setTeam(cleanBG)
}, [])

const getteam = useCallback( async () => {
  //setIsTestimonials(true)
  try{
    const teamresponse = await client.getEntries({ content_type: 'team' })
    const responseData = teamresponse.items
    console.log(responseData)

    if(responseData){
      cleanTeam(responseData)
      }else{
        setTeam([])
      }

  }
  catch(error){
    //console.log(error)
    //setIsTestimonials(false)
  }

}, [cleanTeam])

useEffect (() => {
  getteam()
}, [getteam])

  return (
    <>
    <section className='h-[500px] bg-secondary' style={{ backgroundImage: `url(${leadbg})`, }}>
        <div className="container mx-auto h-full flex items-center">
            <h1 className='text-5xl uppercase text-white font-black pb-8 relative after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] after:opacity-10'>Unique Builders Team</h1>
        </div>
    </section>

    <section>
        <div className="container mx-auto -mt-16 ">
            <div className="bg-[#0d3470] z-10 p-10">

            <div className="filter-item grid grid-cols-5">
            <div className="check-div">
                            <input className="chkblog" type="checkbox" id="sales" />
                            <label for="sales">Sales</label>
                        </div>
                        <div className="check-div">
                            <input className="chkblog" type="checkbox" id="construction" />
                            <label for="construction">Construction</label>
                        </div>
                        
                        <div className="check-div">
                            <input className="chkblog" type="checkbox" id="administration" />
                            <label for="administration">Administration</label>
                        </div>
                        
                        <div className="check-div">
                            <input className="chkblog" type="checkbox" id="executive" />
                            <label for="executive">Executive</label>
                        </div>
                    </div>

            </div>
            <div className="grid grid-cols-3 gap-16 py-20">

            {Team.map((item) => {
            const { memberName, positionTitle, teamMemberImage } = item
            return (
              <article className=""><figure><Link to="" className='block relative'><img src={teamMemberImage} alt="" /></Link></figure>
              <div className='uppercase pt-4 text-primary font-bold'>
                {positionTitle}
              </div>
              <h2 className='py-2'><Link to="" className='text-3xl uppercase font-black text-secondary'>
              {memberName}</Link></h2>
              </article> 
            )

            }

            )}
             
             

                    
            </div>
            <div className='text-center mb-32'><Link to='#' className='inline-block text-white uppercase font-bold py-5 px-5 min-w-[272px] bg-primary'>Load more</Link></div>
        </div>
    </section>
    </>
  )
}

export default Team