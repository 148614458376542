import React, { useState, useEffect, useCallback } from 'react'
import { client } from "../../client";
import { Link } from 'react-router-dom';
import leadbg from '../../assets/images/lead-text.png';

const Inspiration = () => {
  const [Inspiration, setInspiration] = useState([])

  const cleanInspiration = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const {id} = sys
        const title = fields.title
        const authorName = fields.authorName
        const inspirationImage = fields.inspirationImage.fields.file.url
        const updatedInspiration = { id, title, authorName, inspirationImage }
        return updatedInspiration
    })
    setInspiration(cleanBG)
}, [])

const getinspiration = useCallback( async () => {
  try{
    const testiresponse = await client.getEntries({ content_type: 'inspiration' })
    const responseData = testiresponse.items
    console.log(responseData)

    if(responseData){
      cleanInspiration(responseData)
      }else{
        setInspiration([])
      }

  }
  catch(error){
    //console.log(error)
    //setIsTestimonials(false)
  }

}, [cleanInspiration])

useEffect (() => {
  getinspiration()
}, [getinspiration])
  return (
    <>
    <section className='h-[500px] bg-secondary' style={{ backgroundImage: `url(${leadbg})`, }}>
        <div className="container mx-auto h-full flex items-center">
            <h1 className='text-5xl uppercase text-white font-black pb-8 relative after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] after:opacity-10'>Inspiration</h1>
        </div>
    </section>

    <section>
        <div className="container mx-auto -mt-16 ">
            <div className="bg-[#0d3470] z-10 p-10">

            <div className="filter-item grid grid-cols-8">
            <div className="check-div">
                          <Link to={`/inspiration/category/horses`}>
                            <input className="chkblog" type="checkbox" id="sales" />
                            <label for="sales">Horses</label>
                          </Link>
                        </div>
                        <div className="check-div">
                        <Link to={`/inspiration/category/hobby`}>
                            <input className="chkblog" type="checkbox" id="construction" />
                            <label for="construction">Hobby</label>
                          </Link>
                        </div>
                        
                        <div className="check-div">
                        <Link to={`/inspiration/category/aircraft`}>
                            <input className="chkblog" type="checkbox" id="administration" />
                            <label for="administration">Aircraft</label>
                          </Link>
                        </div>
                        
                        <div className="check-div">
                        <Link to={`/inspiration/category/homes`}>
                            <input className="chkblog" type="checkbox" id="executive" />
                            <label for="executive">Homes</label>
                          </Link>
                        </div>
                    </div>

            </div>
            <div className="grid grid-cols-3 gap-16 py-20">
             
                {Inspiration.map((item) => {
                const { title, authorName, inspirationImage } = item
                return (
                  <>
                <article className=""><figure><Link to="" className='block relative'><img src={inspirationImage} alt="" /></Link></figure>
                <div className='uppercase pt-4 text-primary font-bold'>
                {authorName}
                </div>
                <h2 className='py-2'><Link to="" className='text-3xl uppercase font-black text-secondary'>
                {title}</Link></h2>
                </article> 
                </> 
                )

                }
  
                )}
       
            </div>
            <div className='text-center mb-32'><Link to='#' className='inline-block text-white uppercase font-bold py-5 px-5 min-w-[272px] bg-primary'>Load more</Link></div>
        </div>
    </section>
    </>
  )
}

export default Inspiration