import React from 'react'
import { Link } from "react-router-dom";
import heroimg from '../../assets/images/banner-home.jpg';
import img01 from '../../assets/images/hot-air-balloons.jpg';
import GetInTouch from "components/GetInTouch";

const CaseStudiesDetails = () => {
    return (
      <>
      <img src={heroimg} alt="" />

      <div className='container mx-auto'>
        <div className='flex items-start justify-between pb-20'>
          <div className='w-4/12 pt-[175px]'>
            <div className='text-xl font-bold text-[#b8b8b8]'><span className='text-primary'>Residential</span></div>
            <h2 className='relative pb-8 mt-8 mb-24 text-5xl text-[#382e2c] font-black uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10'>MANGLAM RAMBAGH</h2>
            <p className='text-lg leading-8 mb-4 text-[#6f6f6f]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut ipsum ut felis convallis venenatis a non odio. Phasellus vitae lectus mattis, finibus massa nec, convallis nisi. Morbi non rhoncus turpis, at dignissim libero.</p>
            <p className='text-lg leading-8 mb-4 text-[#6f6f6f]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut ipsum ut felis convallis venenatis a non odio. Phasellus vitae lectus mattis, finibus massa nec, convallis nisi. Morbi non rhoncus turpis, at dignissim libero.</p>
          </div>
          <div className='-mt-32 w-4/12 bg-[#5b4939]'>
            <div className='py-16 px-16'>
              <h2 className='text-white font-black text-3xl uppercase mb-8'>workshop</h2>
              <div className='py-8 border-t border-[#75675b]'>
                <span className='uppercase font-bold text-[#ada49c]'>Size:</span>
                <p className='text-white font-bold text-2xl'>60 x 120 x 18</p>
              </div>
              <div className='py-8 border-t border-[#75675b]'>
                <span className='uppercase font-bold text-[#ada49c]'>SQ FT:</span>
                <p className='text-white font-bold text-2xl'>1500 SQ FT</p>
              </div>
              <div className='pt-8 border-t border-[#75675b]'>
                <span className='uppercase font-bold text-[#ada49c]'>Special features:</span>
                <ul className='text-white font-medium text-2xl'>
                  <li>• Cupola</li>
                  <li>• Wrap around Porch</li>
                  <li>• Fire Pit</li>
                  <li>• Wanscot</li>
                </ul>
              </div>
            </div>
            <div className='bg-[#f3efe9] py-12 px-16 flex items-center justify-between'>
              <div>
                <p className='uppercase font-bold text-[#ada49c]'>Starting From:</p>
                <p className='font-bold text-3xl'>$100,000</p>
              </div>
              <div><a href='' className='btn'>Get a quote</a></div>
            </div>
          </div>
        </div>
        
      </div>

      <section className='image-gallery-grid'>
        <div className="grid grid-cols-12">
          <div className="col-span-7"><img src={heroimg} className="h-full object-cover" alt='' /></div>
          <div className="col-span-5"><img src={heroimg} className="h-full object-cover" alt='' /><img src={heroimg} /></div>
          <div className="col-span-6"><img src={heroimg} className="h-full object-cover" alt='' /></div>
          <div className="col-span-3"><img src={heroimg} className="h-full object-cover" alt='' /></div>
          <div className="col-span-3"><img src={heroimg} className="h-full object-cover" alt='' /></div>
        </div>
      </section>

      <section className='single-testimonial bg-[#5d4b3b] py-32'>
        <div className='container max-w-[1000px] mx-auto'>
          <p className='text-4xl text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut ipsum ut felis convallis venenatis a non odio. Phasellus vitae lectus mattis, finibus massa nec, convallis nisi. Morbi non rhoncus turpis, at dignissim libero.</p>
          <p className='mt-8 text-[#a89d93] uppercase font-bold'>- Jerad Bonetti</p>
        </div>
      </section>

      <section className='related-case-studies py-36 bg-[#f3efe9]'>
        <div className='container mx-auto'>
          <h2 className='relative pb-8 mt-8 mb-24 text-2xl text-[#382e2c] font-black uppercase text-center after:absolute after:left-1/2 after:-translate-x-1/2 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10'>Related Case studies</h2>

          <div className="grid grid-cols-2 gap-6">
            <div className="">
              <div className="stacked-cta">
                <Link to="#">
                <figure className="h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img01})`, }}></figure>
                <div className="bg-white p-14 relative z-10">
                  <p className='uppercase text-primary font-bold mb-4'>Jared's</p>
                  <h4 className="text-4xl	text-secondary uppercase font-bold mb-3">Acreage studio</h4>
                </div>
                </Link>
              </div>
            </div>
            <div className="">
              <div className="stacked-cta">
                <Link to="#">
                <figure className="h-[600px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${img01})`, }}></figure>
                <div className="bg-white p-14 relative z-10">
                  <p className='uppercase text-primary font-bold mb-4'>Mark below’s</p>
                  <h4 className="text-4xl	text-secondary uppercase font-bold mb-3">FARM HOUSE</h4>
                </div>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </section>
      <GetInTouch />
    </>
  )
}

export default CaseStudiesDetails