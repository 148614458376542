import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../template/Footer";
import Header from "../template/Header";

const AppLayout = () => {
  return <>
   <div className="">
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
   </div>
  </>;
};
export default AppLayout;
