import { Routes, Route } from "react-router-dom"
import AppLayout from "../layout/AppLayout"
import Error from "views/error"
import Home from "views/home/"
import BlogList from "views/blog/BlogList"
import BlogDetails from "views/blog/BlogDetails"
import BlogCategory from "views/blog/BlogCategory"
import CaseStudiesDetails from "views/casestudies/CaseStudiesDetails"
import BuildingCategory from "views/buildings/BuildingCategory"
import About from "views/about"
import Process from "views/process"
import Inspiration from "views/inspiration"
import InspirationCategory from "views/inspiration/InspirationCategory"
import Why from "views/why"
import Careers from "views/careers"
import Buildings from "views/buildings"
import CareerDetails from "views/careers/CareerDetails"
import Team from "views/team"
import Contact from "views/contact"

const Routing = () => {
  return (
    <>
         <Routes>
          <Route path='/'>
            <Route element={<AppLayout />} >
                <Route path='/' element={<Home />} />
                <Route path='about' element={<About />} />
                <Route path='process' element={<Process />} />
                <Route path='why' element={<Why />} />
                <Route path='careers' element={<Careers />} />
                <Route path='careers/:id' element={<CareerDetails />} />
                <Route path='business' element={<Buildings />} />
                <Route path='business/:id' element={<BuildingCategory />} />
                <Route path='blog' element={<BlogList />} />
                <Route path='blog/:id' element={<BlogDetails />} />
                <Route path='blog/category/:catname' element={<BlogCategory />} />
                <Route path='casestudies' element={<CaseStudiesDetails />} />
                <Route path='inspiration' element={<Inspiration />} />
                <Route path='inspiration/category/:catname' element={<InspirationCategory />} />
                <Route path='team' element={<Team />} />
                <Route path='contact' element={<Contact />} />
            </Route>
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
    </>
  )
}

export default Routing