import React from 'react'
import { Link } from 'react-router-dom'
import bg from '../assets/images/footer-bg.jpg'

const GetInTouch = () => {
  return (
    <>
        <section className="h-[400px] lg:h-[767px] bg-cover overflow-hidden relative bg-no-repeat" style={{ backgroundImage: `url(${bg})`, }}>
            <div className="container mx-auto h-full flex justify-center items-center">
              <div className='text-center'>
                  <h2 className='text-2xl font-bold text-white uppercase'>Get in touch</h2>
                  <h3 className='text-3xl lg:text-7xl font-black text-white uppercase max-w-3xl mt-4 mb-16'>Start your dream project today</h3>
                  <Link className="btn !bg-primary" to="#" >See what is possible</Link>
              </div>
            </div>
        </section>    
    </>
  )
}

export default GetInTouch