import FeaturedCasestudiesList from 'components/FeaturedCasestudiesList';
import GetInTouch from 'components/GetInTouch';
import React, { useState, useEffect, useCallback } from 'react'
import { client } from '../../client'
import {  useParams } from 'react-router-dom'
//import heroimg from '../../assets/images/buildings.jpg';
import icon01 from '../../assets/images/icon-01.png';
import icon02 from '../../assets/images/icon-02.png';
import icon03 from '../../assets/images/icon-03.png';
import testi from '../../assets/images/testi.jpg';
import iconPlay from '../../assets/images/icon-play.png';
import leadText from '../../assets/images/lead-text.png';




const BuildingCategory = () => {
  const [singleBuildingPost, setSingleBuildingPost] = useState([])
  const { id } = useParams()

  const cleanBuildingDetail = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {fields} = home
        const buildingtitle = fields.title
        const buildingdetail = fields.description.content[0].content[0].value
        const buildinshortintro = fields.shortIntro
        const buildingbanner = fields.bannerImage.fields.file.url
        
        const updatedBuildingDetail = { buildingtitle, buildingdetail, buildinshortintro, buildingbanner }
        return updatedBuildingDetail
    })
    setSingleBuildingPost(cleanBG)
}, [])

  const getsinglebuilding = useCallback( async () => {
    //setIsTestimonials(true)
    try{
      const buildingresponse = await client.getEntries({ content_type: 'buildings', 'fields.slug[match]': id })
      const responseData = buildingresponse.items
      console.log(responseData)
      if(responseData){
        cleanBuildingDetail(responseData)
        }else{
          setSingleBuildingPost([])
        }
        //setIsTestimonials(false)
    }
    catch(error){
      //console.uilding(error)
      //setIsTestimonials(false)
    }
  
  }, [cleanBuildingDetail])
  useEffect (() => {
    getsinglebuilding()
  }, [getsinglebuilding])

  const [Testimonials, setTestimonials] = useState([])

  const cleanTestimonials = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const {id} = sys
        const personName = fields.personName
        const reviewHeading = fields.reviewHeading
        const personReview = fields.personReview.content[0].content[0].value
        const personImage = fields.personImage.fields.file.url
        const updatedTestimonials = { id, personName, personReview, reviewHeading, personImage }
        return updatedTestimonials
    })
    setTestimonials(cleanBG)
}, [])

const gettestimonials = useCallback( async () => {
  //setIsTestimonials(true)
  try{
    const testiresponse = await client.getEntries({ content_type: 'testimonials', 'fields.relatedBulidings.sys.contentType.sys.id':'buildings', 'fields.relatedBulidings.fields.slug[match]': 'buildings' })
    const responseData = testiresponse.items
    console.log(responseData)

    if(responseData){
      cleanTestimonials(responseData)
      }else{
        setTestimonials([])
      }

      //setIsTestimonials(false)
  }
  catch(error){
    //console.log(error)
    //setIsTestimonials(false)
  }

}, [cleanTestimonials])

useEffect (() => {
  gettestimonials()
}, [gettestimonials])

  return (
    <>
    {singleBuildingPost.map((item) => {
          const { buildingtitle, buildingdetail, buildinshortintro, buildingbanner } = item
          return (
            <>
              <section className="relative before:absolute before:bg-gradient-to-t before:from-transparent before:via-transparent before:to-black before:opacity-60 before:w-full before:h-full before:z-10">
      <div className="h-screen relative bg-cover bg-no-repeat bg-center flex items-center"
      style={{ backgroundImage: `url(${buildingbanner})` }} >
            <div className="container mx-auto">
                <div className="max-w-3xl">
                <h3 className='text-4xl uppercase text-white font-bold relative block mb-4'>{buildingtitle}</h3>
                <h2 className='text-5xl uppercase text-white font-bold relative block after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-tertiary after:w-full after:max-w-[208px] pb-8 max-w-md'>{buildinshortintro}</h2>
                </div>
            </div>
      </div>
    </section>

    <section className='py-36'>
      <div className="container mx-auto">
        <div className="grid grid-cols-2 gap-36">
          <div className="col-span">
                  <div className="text-gray uppercase text-2xl font-bold mb-3">BUILDINGS / <span className='text-primary'>{buildingtitle}</span> </div>

                  <div className='entry text-[#6f6f6f]'>
                  {buildingdetail}
                  </div>
          </div>
          <div className="col-span">
            <div className="border-b mb-8 pb-8 flex">
                <div className="w-2/12"><img src={icon01} alt="" /></div>
                <div className="w-10/12 pl-4">
                  <h4 className='text-4xl uppercase text-secondary font-bold mb-2'>01. WORKSHOOPS</h4>
                  <p className='text-xl font-bold'>It is a long established fact that a reader will be distracted by the readable content of</p>
                </div>
            </div>
            <div className="border-b mb-8 pb-8 flex">
                <div className="w-2/12"><img src={icon02} alt="" /></div>
                <div className="w-10/12 pl-4">
                  <h4 className='text-4xl uppercase text-secondary font-bold mb-2'>02.STORAGE</h4>
                  <p className='text-xl font-bold'>It is a long established fact that a reader will be distracted by the readable content of</p>
                </div>
            </div>
            <div className="border-b mb-8 pb-8 flex">
                <div className="w-2/12"><img src={icon03} alt="" /></div>
                <div className="w-10/12 pl-4">
                  <h4 className='text-4xl uppercase text-secondary font-bold mb-2'>03. residence</h4>
                  <p className='text-xl font-bold'>It is a long established fact that a reader will be distracted by the readable content of</p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
            </>
          )

      }
      
      )}
     
     {Testimonials.map((item) => {
          const {personName, personReview, reviewHeading, personImage} = item
          return (
            <section className='py-36 bg-[#0d3470] bg-no-repeat' style={{ backgroundImage: `url(${leadText})` }}>
        <div className="container mx-auto">
              <div className="grid grid-cols-2 gap-36">
              <div className="">
                    <img src={personImage} alt="" />
                  </div>
                  <div className="place-self-center">
                      <blockquote className="relative ml-6 text-white before:content-['“']  before:text-[270px] before:leading-none  before:absolute before:top-[-40px] before:left-[-120px]">
                        <p className='text-4xl'>{personReview}</p>
                      </blockquote>
                      <div className="uppercase font-bold text-lg text-white/50 mt-8 ml-6">
                        {personName}
                      </div>
                      <div className='flex items-center ml-6 mt-20'>
                        <p className='text-white font-bold'>WATCH VIDEO</p>
                        <img src={iconPlay} alt="" className='ml-3' />
                      </div>
                  </div>
                 
              </div>
        </div>
    </section>
          )

      }
      
      )}
    

    <section className='py-36'>
      <div className="container mx-auto">
      <h3 className="text-3xl text-secondary block pb-8 mb-24 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10">Case studies</h3>
      </div>

      
      <FeaturedCasestudiesList />

    </section>


    <GetInTouch />
    </>
  )
}

export default BuildingCategory