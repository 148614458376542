import React, { useState, useEffect, useCallback } from 'react'

import { Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { client } from "../client";



// import required modules


const Testimonial = () => {
    //const [istestimonials, setIsTestimonials] = useState(false)
    const [Testimonials, setTestimonials] = useState([])

  const cleanTestimonials = useCallback((rawData) => {
    const cleanBG = rawData.map((home) => {
        const {sys, fields} = home
        const {id} = sys
        const personName = fields.personName
        const reviewHeading = fields.reviewHeading
        const personReview = fields.personReview.content[0].content[0].value
        const personImage = fields.personImage.fields.file.url
        const updatedTestimonials = { id, personName, personReview, reviewHeading, personImage }
        return updatedTestimonials
    })
    setTestimonials(cleanBG)
}, [])

const gettestimonials = useCallback( async () => {
  //setIsTestimonials(true)
  try{
    const testiresponse = await client.getEntries({ content_type: 'testimonials' })
    const responseData = testiresponse.items
    console.log(responseData)

    if(responseData){
      cleanTestimonials(responseData)
      }else{
        setTestimonials([])
      }

      //setIsTestimonials(false)
  }
  catch(error){
    //console.log(error)
    //setIsTestimonials(false)
  }

}, [cleanTestimonials])

useEffect (() => {
  gettestimonials()
}, [gettestimonials])

  return (
    <>
    <section className="pt-12 lg:pt-28 pb-12 lg:pb-36 bg-tertiary overflow-hidden">
    <div className="container mx-auto">
      <h3 className="text-3xl text-secondary block pb-8 mb-12 lg:mb-24 font-bold relative uppercase after:absolute after:left-0 after:bottom-0 after:h-[11px] after:bg-[#382e2c] after:w-full after:max-w-[208px] after:opacity-10">Customer Experiences</h3>
    </div>
    <div className="testimonials-slider container mx-auto">
    <Swiper
        slidesPerView={'auto'}
        spaceBetween={30}
        centeredSlides={false}
        navigation
        modules={[Navigation]}
        className="mySwiper"
      >
        {Testimonials.map((item) => {
          const {personName, personReview, reviewHeading, personImage} = item
          return (
            <SwiperSlide>
            <div className='bg-white shadow-xl px-8 lg:px-20 py-8 lg:py-20'>
                <img src={personImage} className="rounded-full" alt="" />
                <h2 className='text-3xl text-secondary my-4 font-bold'>{reviewHeading}</h2>
                <p className='text-lg italic text-[#636363] pb-4'>{personReview}</p>
                <h3 className='text-primary uppercase font-bold'>{personName}</h3>
            </div>
        </SwiperSlide>
          )

      }
      
      )}
        
      </Swiper>
      </div>
    </section>
    </>
  )
}

export default Testimonial